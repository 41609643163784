@import '~tailwind-color-palette/scss/tailwind-color-palette';

$primary: map-get($tw_indigo, 900);
$secondary: map-get($tw_gray, 700);
$success: map-get($tw_green, 500);
$info: map-get($tw_blue, 400);
$warning: map-get($tw_yellow, 400);
$danger: map-get($tw_red, 600);
$light: map-get($tw_gray, 100);
$dark: map-get($tw_gray, 900);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

@import '~bootstrap/scss/bootstrap';
